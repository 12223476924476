import { PiRegister } from "@pihanga/core";

// import { init as tbPageInit } from "./_tbPage";
import { init as confirmDeleteInit } from "./tbConfirmDelete";
// import { init as emptyListInit } from "./_tbEmptyList";
import { init as customLoginPageInit } from "./tbLoginPage";
// import { init as stepsInit } from "./_tbSteps";
// import { init as cardInit } from "./_tbCard";
import { init as gmapInit } from "./googleMap";
import { init as dropInit } from "./fileDrop";

// import { init as dataTableInit } from "./_tbDataTable";

export function init(register: PiRegister): void {
  confirmDeleteInit(register);
  customLoginPageInit(register);
  gmapInit(register);
  dropInit(register);
}
