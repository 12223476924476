import {
  setAccessToken,
  getAccessToken,
  clearAccessToken,
} from "@pihanga/ivcap";

const ACCESS_TOKEN_STORAGE_KEY = "ibenthosUIAccessKey";

const isWebStorageSupported = typeof Storage !== "undefined";
export const saveAndSetAccessToken = (token: string): void => {
  if (isWebStorageSupported) {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
  }

  setAccessToken(token);
};

export const getSavedAccessToken = (): string | undefined => {
  if (isWebStorageSupported) {
    const tmp = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) as string;
    if (tmp && getAccessToken() !== tmp) {
      setAccessToken(tmp);
    }
    return tmp;
  }

  return getAccessToken();
};

export const removeAccessToken = (): void => {
  if (isWebStorageSupported) {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  }

  clearAccessToken();
};
