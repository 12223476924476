import { AppState } from "./app.type";
import { registerTbIcon } from "@pihanga/tabler/dist/components";
import {
  IconChecklist,
  IconBox,
  IconRefresh,
  IconShip,
  IconBuildingFactory2,
  IconShieldX,
  IconBooks,
  IconMap2,
  IconTable,
} from "@tabler/icons-react";

import { TbLoginPageCustom } from "./cards/tbLoginPage";
import { PiCardDef } from "@pihanga/core";
import { TbPage } from "@pihanga/tabler/dist/cards/tbPage" // "./cards/tbPage";
import { TbButton } from "@pihanga/tabler/dist/cards/tbButton";
import { TbButtonType } from "@pihanga/tabler/dist/cards";
import { TbSpinner } from "@pihanga/tabler/dist/cards/tbSpinner";
import { PiSwitcher } from "@pihanga/cards/dist/piSwitcher";

import { getAccessToken } from "@pihanga/ivcap";

import { ReactComponent as AvatarIcon } from "./assets/img/user2.svg";

import { init as analysisInit } from "./analysis/analysis.pihanga";

import { Auth0LoginButton } from "./auth0LoginButton.component";
import { TbModalCard } from "@pihanga/tabler/dist/cards/tbModalCard";
import { ERROR_ROUTE_PATH } from "./app.reducers";
import { PiJsonViewer } from "@pihanga/cards/dist/jsonViewer";
import { TbConfirmDelete } from "./cards/tbConfirmDelete";
import React from "react";

registerTbIcon("collection", IconBooks);
registerTbIcon("job", IconChecklist);
registerTbIcon("artifact", IconBox);
registerTbIcon("refresh", IconRefresh);
registerTbIcon("map", IconMap2);
registerTbIcon("table", IconTable);
registerTbIcon("test", IconBuildingFactory2);
registerTbIcon("ship", IconShip);

const DEF_PAGE = "analysis";

// const TABS = [
//   { id: "collections", title: "Collections", icon: "collection" },
//   { id: "orders", title: "Jobs", icon: "job" },
// ];

const getCardForPath = (path: string[]): string => {
  const primary = path[0];
  if (path.length >= 2) {
    const action = path[path.length - 1];
    if (action === CREATE_ROUTE_PATH) {
      return `${primary}CreateDetail`;
    } else {
      return `${primary}Detail`;
    }
  } else {
    return `${primary}Listing`;
  }
};

export const EDIT_ROUTE_PATH = "edit";
export const CREATE_ROUTE_PATH = "create";
export const CONFIRM_DELETE_ROUTE_PATH = "confirm-delete";
const getContentCard = (s: AppState): string => {
  const path = s.route?.path || [DEF_PAGE];
  let primary = path[0];
  if (primary === "login" || primary === "logout") {
    return "loginPageCustom";
  }

  const lastPath = path[path.length - 1];
  if (lastPath === ERROR_ROUTE_PATH) {
    return "errorCard";
  } else if (lastPath === CONFIRM_DELETE_ROUTE_PATH) {
    return "confirmDelete";
  }

  if (primary === DEF_PAGE) {
    if (path.length <= 2 || path[2] === EDIT_ROUTE_PATH) {
      return getCardForPath(path);
    } else {
      return getCardForPath(path.slice(2, path.length));
    }
  } else return getCardForPath(path);
};

const cards: { [k: string]: PiCardDef } = {};

cards.page = PiSwitcher<AppState>({
  childCard: (s) => {
    const path = s.route?.path || [DEF_PAGE];
    const primary = path[0];
    return getAccessToken() &&
      s.authorization.authorized &&
      primary !== "login" &&
      primary !== "logout"
      ? "mainPage"
      : "loginPageCustom";
  },
});

cards.loginPageCustom = TbLoginPageCustom<AppState>({
  title: "Welcome to the AI Discovery Tool",
  errorMessage: (s) => s.authorization?.errorMessage,
  showLoginTokenForm: process.env.REACT_APP_SHOW_LOGIN_TOKEN_FORM === "true",
  tokenPlaceholder:
    'Token ... via "ivcap context get access-token --refresh-token"',
  providerPrefix: "Login with",
  showLoginPasswordForm: false,
  showSignupLink: false,
  //providerPrefix: 'Continue with',

  authProviders: (s) => [
    {
      loginButton: (
        onGetAuthTokenSuccess,
        onGetAuthTokenError
      ): React.ReactNode =>
        Auth0LoginButton({
          authorizationErrorMsg: s.authorization?.errorMessage,
          label: "Login with Auth0",
          icon: IconShieldX,
          justSignedOut: s.authorization?.justSignedOut,
          onGetAuthTokenSuccess,
          onGetAuthTokenError,
        }),
    },
    // {
    //   name: 'Google',
    //   icon: IconBrandGoogle,
    // },
  ],
});

// cards.mainPageWrapper = PiModalCard<AppState>({
//   contentCard: "mainPage",
//   modalCard: (s) => s.modal?.modalCard,
//   contentLabel: (s) => s.modal?.contentLabel,
// });

cards.mainPage = TbPage<AppState>({
  title: (s) => "AI Discovery Service",
  // titleIcon: LogoIcon,
  // actionBar: (s) => {
  //   if (s.route.path?.length === 1 && s.route?.path[0] === DEF_PAGE) {
  //     return "";
  //   } else return "actionBar";
  // },
  // navBarDropdown: (s) => ({
  //   selectedItemId: getProjectId(s),
  //   selectableItems: !s.projectListing.fetching
  //     ? s.projectListing.list.map((item) => ({
  //       id: item.id,
  //       title: item.name,
  //       onClick: (): void => {
  //         dispatch(createSelectProjectAction(item.id));
  //       },
  //     }))
  //     : undefined,
  //   fetching: s.projectListing.fetching,
  //   actions: [
  //     {
  //       title: "Create new project",
  //       onClick: (): void => {
  //         dispatch(createCreateNewProjectAction());
  //       },
  //     },
  //     {
  //       title: "View projects",
  //       onClick: (): void => {
  //         dispatch(createShowProjectListingAction());
  //       },
  //     },
  //   ],
  // }),
  // messages: [
  //   { title: 'Title', message: 'Message foof' },
  // ],
  //userName: 'Max',
  userAvatar: AvatarIcon,
  contentCard: getContentCard,
  // bottomLinks: [
  //   {
  //     title: "Documentation",
  //     url: "https://github.com/reinventingscience/ivcap-docs",
  //   },
  //   {
  //     title: "Source",
  //     url: "https://github.com/reinventingscience/ivcap-core",
  //   },
  // ],
  version: (s) => s.version,
});

// cards.artifactPreview = TbModalCard<AppState>({
//   title: (s) => s.artifactModal?.title,
//   bodyCard: "artifactImage",
//   downloadName: (s) => s.artifactModal?.title || "???",
//   downloadBlob: (s) => s.artifactModal?.imgURL || undefined,
// });

// cards.artifactImage = PiImageCard<AppState>({
//   imgURL: (s) => s.artifactModal?.imgURL || "",
//   caption: (s) => s.artifactModal?.title || "",
// });

// cards.actionBar = TbActionBar<AppState>({
//   activeTab: (s) => {
//     const path = s.route?.path || [DEF_PAGE];
//     if (path[0] === "projects" && path.length > 2) {
//       return path[2];
//     }

//     return path[0];
//   },
//   tabs: TABS,
//   showSearch: false,
// });

cards.refreshButton = TbButton<AppState>({
  name: "refresh",
  title: "Refresh",
  isGhostButton: true,
  buttonType: TbButtonType.Secondary,
  style: {
    textTransform: "uppercase",
  },
  iconName: "refresh", // defined in app.component
  iconStyle: {
    marginRight: "calc(var(--tblr-btn-padding-x) / 4)",
  },
});

cards.spinnerCard = TbSpinner<AppState>({
  // height: 400,
  // color: 'gray-400',
  spinnerStyle: {
    //paddingTop: "30px",
    //height: "150px",
  },
});

cards.errorCard = TbModalCard<AppState>({
  title: (s) => "Sorry an error has occurred",
  bodyCard: "errorCardBody",
});

cards.errorCardBody = PiJsonViewer<AppState>({
  source: (s: any) => s.error,
});

cards.confirmDelete = TbConfirmDelete<AppState>({
  title: (s) => s.confirmDelete?.title,
});

// orderInit(cards);
analysisInit(cards);
// collectionInit(cards);
// aiBotInit(cards);

export default cards;
