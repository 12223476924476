
import { dispatch, PiCardDef } from "@pihanga/core";
import {
  AppState,
  Order,
  AnalysedLibrary,
  UploadStatus,
} from "../app.type";
import { TbButtonType } from "@pihanga/tabler/dist/cards";
import { TbButton } from "@pihanga/tabler/dist/cards/tbButton";
import {
  ColumnType,
  Row,
  TbXlDataTable,
} from "@pihanga/tabler/dist/cards/tbDataTable";
import { TbXlCard } from "@pihanga/tabler/dist/cards/tbXLCard";
import { PiFlexGrid } from "@pihanga/cards/dist/flexGrid";
import {
  DataGridEl,
  DataGridElType,
  TbDataGrid,
} from "@pihanga/tabler/dist/cards/tbDataGrid";
import { TbEmptyList } from "@pihanga/tabler/dist/cards/tbEmptyList";
import { createRequestAnalysisAction } from "./analysis.action";
import { PiFileDrop } from "../cards/fileDrop";

const dataFormatter = new Intl.DateTimeFormat([], { dateStyle: 'short', timeStyle: 'short' })

export function init(cards: { [k: string]: PiCardDef }): void {
  cards.analysisListing = TbXlCard<AppState>({
    title: "Analysis",
    contentCard: "analysisListingCard", // "analysisTable",
    //actionCards: ["createAnalysisButton"],
    infoCards: ["refreshButton"],
  });

  cards.analysisListingCard = PiFlexGrid<AppState>({
    cards: ["uploadArtifact", "analysisTable"],
    template: {
      area: [[0], [1]],
      rows: ["auto"],
      columns: ["auto"],
      gap: "10px",
    },
  });

  cards.uploadArtifact = PiFileDrop<AppState>({
    showProgress: (s) => s.uploading.status === UploadStatus.InProgress,
    progress: (s) => s.uploading.progress,
    fileTypes: ['apk'],

  })

  cards.emptyAnalysisTable = TbEmptyList<AppState>({
    title: "No analysis found.",
    actionButtonTitle: "Drop apk file onto drop zone",
    actionButtonOnClick: () => () => dispatch(createRequestAnalysisAction()),
  });

  cards.analysisTable = TbXlDataTable<AppState, Order>({
    columns: [
      { label: "idShort", title: "ID", sortable: true, type: ColumnType.String },
      { label: "apkName", title: "APK", sortable: true, type: ColumnType.String },
      { label: "status", title: "Status", sortable: true, type: ColumnType.Status },
      { label: "librariesFound", title: "AI Libraries", sortable: true, type: ColumnType.String },
      { label: "orderedAt", title: "Ordered At", sortable: true, type: ColumnType.Date },
      { label: "name", title: '', type: ColumnType.Hidden },
    ],
    data: (s: AppState) => {
      const data = Object.values(s.orders)
        .sort((a, b) => b.orderedAt.getTime() - a.orderedAt.getTime())
        .map((el, idx) => {
          return {
            id: el.id,
            data: {
              ...el,
              name: el.name,
              apkName: `${el.name.slice(0, 16)}...`,
              librariesFound: el.libraries ? el.libraries.length : '...'
            },
            detailCard: el.status === 'succeeded' ? "analysisDetailCard" : undefined,
          };
        });
      console.log(">>> analysisTable:data", data)
      return data;
    },
    hasDetails: true,
    manageDetails: true,
    showSearch: false,
    showPageSizeSelector: false,
    dataFormatter: {
      'date': (el: Date) => dataFormatter.format(el),
    },
    // dataOffset: 10,
    // hasMore: true,

    cardOnEmpty: (s) => s.analysisListing.fetching ? "spinnerCard" : "emptyAnalysisTable",
  });

  cards.analysisDetailCard = TbDataGrid<AppState>({
    title: (s, _, ctxt) => {
      return (ctxt["row"] as Row<Order>).data?.name;
    },
    items: (s, _, ctxt) => {
      const analysis = (ctxt["row"] as Row<Order>).data;
      return analysisDetail(s, analysis.id);
    },
  });

  cards.createAnalysisButton = TbButton<AppState>({
    name: "create-new-analysis",
    title: "Request New Analysis",
    isGhostButton: true,
    buttonType: TbButtonType.Secondary,
    style: {
      textTransform: "uppercase",
    },
    iconName: "plus",
    iconStyle: {
      marginRight: "calc(var(--tblr-btn-padding-x) / 16)",
    },
  });

  cards.librariesListing = TbXlDataTable<AppState, AnalysedLibrary>({
    columns: [
      { label: 'name', title: 'Name', type: ColumnType.String },
      { label: 'analysis', title: 'Analysis', type: ColumnType.String },
    ],
    data: (s, _, { orderID }) => {
      const libraries = s.orders[orderID]?.libraries || []
      return libraries.map((p, idx) => {
        const id = p.name;
        return {
          id,
          data: { ...p },
        }
      })
    },
    showFooter: false,
  })
}

function analysisDetail(state: AppState, id: string): DataGridEl[] {
  const or = state.orders[id]
  if (!or) return []

  let a: DataGridEl[] = [];

  a.push({
    id: 'orderID',
    title: 'Order ID',
    type: DataGridElType.Text,
    value: or.id,
  })
  a.push({
    id: 'orderedAt',
    title: 'Ordered At',
    type: DataGridElType.Date,
    value: or.orderedAt,
  })
  a.push({ type: DataGridElType.Separator })

  a.push({
    id: 'summary',
    title: 'Summary',
    type: DataGridElType.Text,
    value: or.summary || 'No summary available',
  })
  a = a.concat(libraryListing(or))
  return a
}

function libraryListing(order: Order): DataGridEl[] {
  const libraries = order.libraries
  if (!libraries || libraries.length === 0) {
    return []
  }
  return [
    { type: DataGridElType.Separator },
    {
      id: '',
      title: 'Libraries Used',
      type: DataGridElType.Card,
      cardName: 'librariesListing',
      context: { orderID: order.id }
    }
  ]
}


