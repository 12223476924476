import { AppState, UploadStatus } from "./app.type";
import { getSavedAccessToken, saveAndSetAccessToken } from "./app.localStorage";

const VERSION = document.querySelector<HTMLMetaElement>(
  '[name="ivcap:version"]'
)?.content;

const IVCAP_API_URL = document.querySelector<HTMLMetaElement>(
  '[name="ivcap:api-url"]'
)?.content;

const IVCAP_FEATURE_FLAGS = document.querySelector<HTMLMetaElement>(
  '[name="ivcap:feature-flags"]'
)?.content;

const AUTO_LOGIN_TOKEN = process.env.REACT_APP_AUTO_LOGIN_TOKEN;
const state: AppState = {
  analysisListing: { offset: -1, fetching: false, refreshScheduled: false },
  orders: {},
  uploading: { status: UploadStatus.Idle },

  version:
    VERSION && VERSION !== "@@VERSION@@"
      ? VERSION
      : process.env.REACT_APP_VERSION || "???",

  ivcapApi:
    IVCAP_API_URL && IVCAP_API_URL !== "@@API_URL@@"
      ? new URL(IVCAP_API_URL)
      : new URL(process.env.REACT_APP_API_URL || "http:unknown.com"),

  error: undefined,

  authorization: {
    authorized: AUTO_LOGIN_TOKEN ? true : false,
  },

  route: {
    // remove after fixing @pihanag/core/router/router.reducer.js:
    //   registerReducer('REDUX', 'INIT', function (state) {
    //     var url;
    //     if (state.route.path) { << should check for 'empty' path
    path: null,
    query: {},
  },

  featureFlags: getFeatureFlags(),

  // pihanga: {},
};

// if (getFeatureFlags().has("debug-state")) {
//   require('./app.state.debug').default(state);
// }

if (AUTO_LOGIN_TOKEN) {
  saveAndSetAccessToken(AUTO_LOGIN_TOKEN);
} else {
  getSavedAccessToken();
}

function getFeatureFlags(): Set<string> {
  let ff: string;
  if (IVCAP_FEATURE_FLAGS && IVCAP_FEATURE_FLAGS !== "@@FEATURE_FLAGS@@") {
    ff = IVCAP_FEATURE_FLAGS;
  } else {
    ff = process.env.REACT_APP_FEATURE_FLAGS || "";
  }
  return new Set(ff.split(","));
}

export default state;
