import { ReduxAction, createOnAction, registerActions } from "@pihanga/core";
import {
  ArtifactDataEvent,
  ArtifactRecord,
  MetadataQueryResultItem,
} from "@pihanga/ivcap";

export const ACTION_TYPES = registerActions("APP", [
  // orders
  "ADD_ORDER",
  "ORDER_ADDED",

  "SHOW_ORDER_LISTING",
  "SHOW_ORDER_DETAIL",
  "ORDER_METADATA",

  // artifacts
  "SHOW_ARTIFACT_LISTING",
  "SHOW_ARTIFACT_DETAIL",
  "REQUEST_ARTIFACT_IN_MODAL",
  "SHOW_ARTIFACT_IN_MODAL",
  "ARTIFACT_METADATA",

  "ERROR",
]);

// SHOW_ORDER_LISTING

export type ShowOrderListingEvent = {};

export const onShowOrderListing = createOnAction<ShowOrderListingEvent>(
  ACTION_TYPES.SHOW_ORDER_LISTING
);
export const createShowOrderListingAction = (): ReduxAction &
  ShowOrderListingEvent => ({
    type: ACTION_TYPES.SHOW_ORDER_LISTING,
  });

// SHOW_ORDER_DETAIL

export type ShowOrderDetailEvent = {
  orderID: string;
};

export const onShowOrderDetail = createOnAction<ShowOrderDetailEvent>(
  ACTION_TYPES.SHOW_ORDER_DETAIL
);
export const createShowOrderDetailAction = (
  orderID: string
): ReduxAction & ShowOrderDetailEvent => ({
  type: ACTION_TYPES.SHOW_ORDER_DETAIL,
  orderID,
});

// ORDER_METADATA

export type OrderMetadataEvent = {
  orderID: string;
  records: MetadataQueryResultItem[];
};

export const onOrderMetadata = createOnAction<OrderMetadataEvent>(
  ACTION_TYPES.ORDER_METADATA
);
export const createOrderMetadataAction = (
  orderID: string,
  records: MetadataQueryResultItem[]
): ReduxAction & OrderMetadataEvent => ({
  type: ACTION_TYPES.ORDER_METADATA,
  orderID,
  records,
});

// SHOW_ARTIFACT_LISTING

export type ShowArtifactListingEvent = {};

export const onShowArtifactListing = createOnAction<ShowArtifactListingEvent>(
  ACTION_TYPES.SHOW_ARTIFACT_LISTING
);
export const createShowArtifactListingAction = (): ReduxAction &
  ShowArtifactListingEvent => ({
    type: ACTION_TYPES.SHOW_ARTIFACT_LISTING,
  });

// SHOW_ARTIFACT_DETAIL

export type ShowArtifactDetailEvent = {
  artifactID: string;
};

export const onShowArtifactDetail = createOnAction<ShowArtifactDetailEvent>(
  ACTION_TYPES.SHOW_ARTIFACT_DETAIL
);
export const createShowArtifactDetailAction = (
  artifactID: string
): ReduxAction & ShowArtifactDetailEvent => ({
  type: ACTION_TYPES.SHOW_ARTIFACT_DETAIL,
  artifactID,
});

// REQUEST_ARTIFACT_IN_MODAL

export type RequestArtifactInModalEvent = {
  artifact: ArtifactRecord;
};

export const onRequestArtifactInModal =
  createOnAction<RequestArtifactInModalEvent>(
    ACTION_TYPES.REQUEST_ARTIFACT_IN_MODAL
  );
export const createRequestArtifactInModalAction = (
  artifact: ArtifactRecord
): ReduxAction & RequestArtifactInModalEvent => ({
  type: ACTION_TYPES.REQUEST_ARTIFACT_IN_MODAL,
  artifact,
});

// SHOW_ARTIFACT_IN_MODAL

export type ShowArtifactInModalEvent = {
  title: string;
  artifact: ArtifactDataEvent;
};

export const onShowArtifactInModal = createOnAction<ShowArtifactInModalEvent>(
  ACTION_TYPES.SHOW_ARTIFACT_IN_MODAL
);
export const createShowArtifactInModalAction = (
  title: string,
  artifact: ArtifactDataEvent
): ReduxAction & ShowArtifactInModalEvent => ({
  type: ACTION_TYPES.SHOW_ARTIFACT_IN_MODAL,
  title,
  artifact,
});

// ARTIFACT_METADATA

export type ArtifactMetadataEvent = {
  // type: ACTION_TYPES.ARTIFACT_METADATA,
  artifactID: string;
  records: MetadataQueryResultItem[];
};

export const onArtifactMetadata = createOnAction<ArtifactMetadataEvent>(
  ACTION_TYPES.ARTIFACT_METADATA
);
export const createArtifactMetadataAction = (
  artifactID: string,
  records: MetadataQueryResultItem[]
): ReduxAction & ArtifactMetadataEvent => ({
  type: ACTION_TYPES.ARTIFACT_METADATA,
  artifactID,
  records,
});
