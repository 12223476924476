import {
  dispatch,
  dispatchFromReducer,
  PiRegister,
  RouterShowPageAction,
  update,
} from "@pihanga/core";

import {
  dispatchIvcapCreateOrder,
  dispatchIvcapGetArtifactData,
  dispatchIvcapGetOrderRecord,
  dispatchIvcapQueryMetadata,
  dispatchIvcapUploadArtifactData,
  onArtifactUploaded,
  onArtifactUploadProgress,
  onMetadataAdded,
  onMetadataRevoked,
  onMetadataUpdated,
  onOrderReceipt,
  onOrderRecord,
  URN,
} from "@pihanga/ivcap";
import { AppState, UploadStatus } from "../app.type";
import {
  ACTION_TYPES,
  createShowAnalysisListingAction,
  dispatchRefresh,
  onAnalysisOrderListing,
  onAnalysisResult,
  onPlaceOrder,
  onRefresh,
  onShowAnalysisIndividual,
  onShowAnalysisListing,
} from "./analysis.action";

import { onTbXlDataTableRowSelect } from "@pihanga/tabler/dist/cards/tbDataTable";
import { onFileDropped } from "../cards/fileDrop";
import { onTbButtonClicked } from "@pihanga/tabler/dist/cards/tbButton";

const SERVICE_ID = 'urn:ivcap:service:30609352-fab7-50f1-a7f3-83d9a9aac58f'
const PROJECT_SCHEMA = "urn:ibenthos:schema:project";

export function init(register: PiRegister): void {
  register.reducer<AppState, RouterShowPageAction>("ROUTER:SHOW_PAGE", (state, { path }) => {
    if (path.length === 0) {
      return state;
    }

    const page = path[0];
    if (page === "analysis") {
      dispatchRefresh()
    }
    return state;
  });

  onTbButtonClicked<AppState>(register, (state, { name }) => {
    if (name === "refresh") {
      dispatchRefresh()
    }

    return state;
  });

  onRefresh<AppState>(register, (state) => {
    dispatchFromReducer(createShowAnalysisListingAction(true));
    return state;
  })

  onShowAnalysisListing<AppState>(register, (state, { updateDataOnly }) => {
    if (!updateDataOnly) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: ["analysis"],
      });
    }

    dispatchIvcapQueryMetadata({
      apiURL: state.ivcapApi,
      schema: "urn:ivcap:schema:order-placed.1",
      filter: "service-id~='urn:ivcap:service:30609352-fab7-50f1-a7f3-83d9a9aac58f'",
      template: {
        type: ACTION_TYPES.ORDER_LISTING,
      },
    });

    return update(state, ["analysisListing"], {
      fetching: true,
      refreshScheduled: false,
    });
  });

  onAnalysisOrderListing<AppState>(register, (state, { records }) => {
    const orders = state.orders
    records.forEach((r) => {
      const orderID = r.entity
      const order = orders[orderID]
      if (!order || !(order.status === "succeeded" || order.status === "error")) {
        dispatchIvcapGetOrderRecord({
          apiURL: state.ivcapApi,
          id: orderID,
        })
      }
    })
    return update(state, ['analysisListing'], {
      offset: 0,
      //list,
      fetchedAt: Date.now(),
      fetching: false
    });
  })

  onOrderRecord<AppState>(register, (state, { order }) => {
    const id = order.id

    const pa = order.products
    if (pa.length > 0) {
      const p = pa[0]
      dispatchIvcapGetArtifactData<{ orderID: string }>({
        apiURL: state.ivcapApi,
        id: p.id,
        dataURL: p.dataURL!,
        template: {
          type: ACTION_TYPES.ANALYSIS_RESULT,
          orderID: id,
        },
      })
    }
    const idShort = id.split(':')[3].slice(0, 8)
    const orderedAt = order.orderedAt
    const status = order.status || 'unknown'
    let s = state
    if (!(status === 'succeeded' || status === 'error')) {
      s = scheduleStatusUpdate(s)
    }
    return update(s, ['orders', id], {
      id,
      idShort,
      name: order.name,
      status,
      orderedAt,
    })

  });

  onAnalysisResult<AppState>(register, (state, { orderID, data }) => {
    //   {
    //     "apk_name": "http://artifact.local/urn:ivcap:artifact:4c485f28-b57b-4da1-92ee-4974496f6e4d",
    //     "summary": [
    //         "The APK appears to be focused on utilizing deep learning, neural network computations, and machine learning tasks in the Android application. It includes libraries such as libcaffe.so and libtensorflow.so to support these functionalities. Additionally, the libcaffe_jni.so library provides the Java Native Interface (JNI) for integrating the libcaffe.so library with the Android application."
    //     ],
    //     "libraries": [
    //         {
    //             "name": "libcaffe.so",
    //             "analysis": "The libcaffe.so library is used for deep learning and neural network computations in the Android application."
    //         },
    //         ...
    //     ],
    //     "order-id": "urn:ivcap:order:583385aa-8d79-4878-bf66-126c8e9424c0",
    //     "$schema": "urn:rai:schema:ai-discovery:analysis.1"
    // }
    return update(state, ['orders', orderID], {
      apkName: data['apk_name'],
      summary: data['summary'][0],
      libraries: data['libraries'],
    })
  });

  onFileDropped<AppState>(register, (state, { file }) => {
    dispatchIvcapUploadArtifactData({
      apiURL: state.ivcapApi,
      file,
    });
    return update(state, ["uploading"], {
      status: UploadStatus.InProgress,
      name: file.name,
      size: file.size,
      type: file.type,
    })
  })

  onArtifactUploadProgress<AppState>(register, (state, { progress }) => {
    return update(state, ["uploading"], { progress: Math.trunc(100 * progress) })
  })

  onArtifactUploaded<AppState>(register, (state, ev) => {
    dispatchFromReducer({ ...ev, type: ACTION_TYPES.PLACE_ORDER })
    return update(state, ["uploading"], {
      progress: 100,
    })
  })

  onPlaceOrder<AppState>(register, (state, { artifactURN, name }) => {
    dispatchIvcapCreateOrder({
      apiURL: state.ivcapApi,
      id: name,
      serviceID: SERVICE_ID,
      parameters: [{
        name: 'apk',
        value: artifactURN,
      }]
    })
    return state
  })

  onOrderReceipt<AppState>(register, (state, { order }) => {
    // that should be handled in a 'step'
    const s = update(state, ["uploading"], { status: UploadStatus.Idle })

    dispatchRefresh() // Quite often the new order doesn't show iup in the the table, foce it!
    const id = order.id
    const s2 = update(s, ['orders', order.id], {
      id,
      idShort: idShort(id),
      name: order.name,
      status: order.status,
      requestedAt: order.orderedAt,
    });
    return s2;
  })

  onTbXlDataTableRowSelect<AppState>(register, (state, { cardID, row }) => {
    if (cardID === "analysisTable") {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: ["analysis", row.id],
      });
    }
    return state;
  });

  onTbButtonClicked<AppState>(register, (state, { name }) => {
    if (name === "create-new-analysis") {
      // orderAnalysis(state);
      return state;
    } else {
      return state;
    }
  });

  onShowAnalysisIndividual<AppState>(register, (state, { id }) => {
    dispatchFromReducer({
      type: "ROUTER:SHOW_PAGE",
      path: ["analysis", id, "edit"],
    });

    return update(state, ["prevMeaningfulRoute"], state.route);
  });

  onMetadataAdded<AppState>(register, (state, ev) => {
    if (ev.schema === PROJECT_SCHEMA) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: ["analysis"],
      });
    }

    return state;
  });

  onMetadataUpdated<AppState>(register, (state, ev) => {
    if (ev.schema === PROJECT_SCHEMA) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: ["analysis"],
      });
    }

    return state;
  });

  onMetadataRevoked<AppState>(register, (state, ev) => {
    // FIXME: "schema" info is not available. Going to "analysis" is most likely incorrect in other cases
    // if (ev.schema === PROJECT_SCHEMA) {
    dispatchFromReducer({
      type: "ROUTER:SHOW_PAGE",
      path: ["analysis"],
    });
    // }

    return state;
  });
}

function scheduleStatusUpdate(state: AppState): AppState {
  if (state.analysisListing.refreshScheduled) {
    return state
  }
  // refresh in 10 sec
  setTimeout(() => {
    dispatch(createShowAnalysisListingAction(true));
  }, 10000)
  return update(state, ["analysisListing", "refreshScheduled"], true)
}

function idShort(id: URN): string {
  return id.split(':')[3].slice(0, 8)
}
