import { ReduxAction, createOnAction, dispatchFromReducer, registerActions } from "@pihanga/core";
import { ArtifactDataEvent, ArtifactUploadedEvent, MetadataQueryResultEvent } from "@pihanga/ivcap";

export const ACTION_TYPES = registerActions("APP:AYL", [
  "ORDER_LISTING",
  "SHOW_LISTING",
  "SHOW_INDIVIDUAL",
  "ANALYSIS_RESULT",
  "REQUEST_ANALYTICS",
  "PLACE_ORDER",
  "REFRESH",
  "ERROR",
]);

export type RequestAnalysisEvent = {};

export const onRequestAnalysisEvent = createOnAction<RequestAnalysisEvent>(
  ACTION_TYPES.REQUEST_ANALYTICS
);
export const createRequestAnalysisAction = (): ReduxAction & RequestAnalysisEvent => ({
  type: ACTION_TYPES.REQUEST_ANALYTICS,
});

export type ShowAnalysisListingEvent = {
  updateDataOnly?: boolean;
};

export const onShowAnalysisListing = createOnAction<ShowAnalysisListingEvent>(
  ACTION_TYPES.SHOW_LISTING
);
export const createShowAnalysisListingAction = (
  updateDataOnly?: boolean
): ReduxAction & ShowAnalysisListingEvent => ({
  type: ACTION_TYPES.SHOW_LISTING,
  updateDataOnly,
});

// Reporting existing analytics orders
export const onAnalysisOrderListing = createOnAction<MetadataQueryResultEvent>(
  ACTION_TYPES.ORDER_LISTING
);

export type ShowAnalysisDetailEvent = {
  id: string;
};

export const onShowAnalysisIndividual = createOnAction<ShowAnalysisDetailEvent>(
  ACTION_TYPES.SHOW_INDIVIDUAL
);
export const createShowAnalysisDetailAction = (
  id: string
): ReduxAction & ShowAnalysisDetailEvent => ({
  type: ACTION_TYPES.SHOW_INDIVIDUAL,
  id,
});

export const onAnalysisResult = createOnAction<ArtifactDataEvent & { orderID: string }>(
  ACTION_TYPES.ANALYSIS_RESULT
);

// PLACE_ORDER

export const onPlaceOrder = createOnAction<ArtifactUploadedEvent>(
  ACTION_TYPES.PLACE_ORDER
);

// REFRESH

export function dispatchRefresh(): void {
  dispatchFromReducer({ type: ACTION_TYPES.REFRESH });
}

export const onRefresh = createOnAction(
  ACTION_TYPES.REFRESH
);
