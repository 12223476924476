import {
  dispatchFromReducer,
  PiRegister,
  ReduxAction,
  RouterShowPageAction,
  update,
} from "@pihanga/core";
import { onTbLoginToken } from "./cards/tbLoginPage";
import {
  init as ivcapInit,
  hasAccessToken,
  onIvcapUnauthorizedError,
  onIvcapError,
} from "@pihanga/ivcap";
import { AppState, AuthorizationState } from "./app.type";
import { onTbPageLogout } from "@pihanga/tabler/dist/cards/tbPage";
import { init as analysisInit } from "./analysis/analysis.reducer";

import {
  ModalState,
  onModalCloseRequest,
} from "@pihanga/cards/dist/modalWrapper";
import { onCloseModal as onCloseModalConfirmDelete } from "./cards/tbConfirmDelete";
import { onConfirmDelete } from "./cards/tbConfirmDelete";
import { ACTION_TYPES, ShowArtifactInModalEvent } from "./app.actions";
import { saveAndSetAccessToken, removeAccessToken } from "./app.localStorage";

const DEF_PAGE = "analysis";

export const ERROR_ROUTE_PATH = "error";

export function init(register: PiRegister): void {
  ivcapInit(register);
  analysisInit(register);

  register.reducer("REDUX:INIT", (state: AppState) => {
    return update(state, ["authorization"], {
      authorized: hasAccessToken(),
    } as AuthorizationState);
  });

  register.reducer<AppState, RouterShowPageAction>(
    "ROUTER:SHOW_PAGE",
    (state, { path }) => {
      if (path.length === 0) {
        // Need to go to login page to verify the authorisation code from Auth0
        const hasCodeFromAuth0 = state.route.query.code;

        dispatchFromReducer({
          type: "ROUTER:SHOW_PAGE",
          path: hasCodeFromAuth0 ? ["login"] : [DEF_PAGE],
        });
        return state;
      }

      const page = path[0];

      if (page === "login" && state.authorization?.authorized) {
        dispatchFromReducer({
          type: "ROUTER:SHOW_PAGE",
          path: [DEF_PAGE],
        });
      } else if (page !== "login" && !state.authorization?.authorized) {
        dispatchFromReducer({
          type: "ROUTER:SHOW_PAGE",
          path: ["login"],
        });
        return update(state, ["authorization"], {
          authorized: false,
          lastPath: path,
        } as AuthorizationState);
      }

      return state;
    }
  );

  onTbLoginToken<AppState>(register, (state, { token, error }) => {
    if (token) {
      saveAndSetAccessToken(token);

      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path:
          state.authorization?.lastPath &&
            state.authorization?.lastPath[0] !== "login"
            ? state.authorization?.lastPath
            : [DEF_PAGE],
      });

      return update(state, ["authorization"], {
        authorized: true,
      } as AuthorizationState);
    } else {
      removeAccessToken();
      return update(state, ["authorization"], {
        authorized: false,
        errorMessage: error || "Invalid token",
      } as AuthorizationState);
    }
  });

  onTbPageLogout<AppState>(register, (state) => {
    removeAccessToken();
    return update(state, ["authorization"], {
      authorized: false,
      justSignedOut: true,
      errorMessage: undefined,
    } as AuthorizationState);
  });

  onIvcapUnauthorizedError<AppState>(register, (state) => {
    if (!hasAccessToken()) {
      return update(state, ["authorization"], {
        authorized: false,
        lastPath: state.route?.path,
      } as AuthorizationState);
    } else {
      removeAccessToken();
      return update(state, ["authorization"], {
        authorized: false,
        errorMessage: "Expired or unknown Token",
        lastPath: state.route?.path,
      } as AuthorizationState);
    }
  });

  // FIXME: This will not catch other errors like "REST:POST_INTERNAL_ERROR:addMetadata"
  // due to a bug in @pihanga/rest-client
  onIvcapError<AppState>(register, (state, error) => {
    dispatchFromReducer({
      type: "ROUTER:SHOW_PAGE",
      path: (state.route.path || []).concat([ERROR_ROUTE_PATH]),
    });

    return update(state, ["error"], error);
  });

  const goBack = (state: AppState): AppState => {
    let s = state;
    s = update(s, ["error"], undefined);

    if (state.prevMeaningfulRoute) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: state.prevMeaningfulRoute.path,
      });

      s = update(s, ["prevMeaningfulRoute"], undefined);
    } else {
      const path = state.route.path;
      if (path && path[path.length - 1] === ERROR_ROUTE_PATH) {
        dispatchFromReducer({
          type: "ROUTER:SHOW_PAGE",
          path: path.slice(0, path.length - 1),
        });
      }
    }

    return s;
  };

  onModalCloseRequest<AppState>(register, goBack);
  onCloseModalConfirmDelete<AppState>(register, goBack);

  onConfirmDelete<AppState>(register, (state) => {
    state.confirmDelete?.performDelete();
    return update(state, ["confirmDelete"], undefined);
  });

  register.reducer<AppState, ReduxAction & ShowArtifactInModalEvent>(
    ACTION_TYPES.SHOW_ARTIFACT_IN_MODAL,
    (state, ev) => {
      const am = { ...ev, imgURL: URL.createObjectURL(ev.artifact.data) };
      const s1 = update(state, ["artifactModal"], am);
      return update(s1, ["modal"], {
        modalCard: "artifactPreview",
      } as ModalState);
    }
  );
}
