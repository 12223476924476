import React from "react";

export type ModalComponentProps = {
  title?: string;
  confirmTitle?: string;
  confirmButtonClass?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  disableConfirm?: boolean;
  children?: React.ReactNode;
};

export const ModalComponent = ({
  title,
  confirmTitle,
  confirmButtonClass = "btn-primary",
  onClose,
  onConfirm,
  children,
  disableConfirm,
}: ModalComponentProps): React.ReactNode => {
  function renderTitle(): React.ReactNode {
    if (!title) return null;

    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        />
      </div>
    );
  }

  const op = {
    className: `modal modal-blur fade show pi-tb-model-card`,
    "aria-modal": true,
    style: { display: "block" },
    tabIndex: -1,
  };

  return (
    <div {...op}>
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          {renderTitle()}

          <div className="modal-body">{children}</div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn me-auto"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Close
            </button>

            {confirmTitle && (
              <button
                type="button"
                className={`btn ${confirmButtonClass}`}
                data-bs-dismiss="modal"
                onClick={onConfirm}
                disabled={disableConfirm}
              >
                {confirmTitle}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
