import type { ReduxState } from "@pihanga/core";
import { ErrorAction } from "@pihanga/ivcap";

type AppRoute = {
  // remove after fixing @pihanag/core/router/router.reducer.js:
  //   registerReducer('REDUX', 'INIT', function (state) {
  //     var url;
  //     if (state.route.path) { << should check for 'empty' path

  path: string[] | null;
  query: { [k: string]: any };
  //path: string[];
};

export type AppState = ReduxState & {
  analysisListing: AnalysisListing;
  orders: { [id: string]: Order };

  uploading: UploadState;

  authorization: AuthorizationState;

  ivcapApi: URL;
  version: string;

  route: AppRoute;
  prevMeaningfulRoute?: AppRoute;

  showing?: string; // urn of current focus
  error?: ErrorAction;
  debug?: { [k: string]: any };
  confirmDelete?: {
    title: string;
    performDelete: () => void;
  };

  featureFlags: Set<string>;
};

export type URN = string;
//export type Date = string;

export type AnalysisListing = {
  offset: number;
  // list: AnalysisSummary[];
  fetchedAt?: number;
  fetching: boolean;
  refreshScheduled: boolean; // scheduled future refresh
}

export type AnalysisSummary = {
  orderID: string;
}

export type Order = {
  id: string;
  idShort: string;
  name: string;
  status: string;
  orderedAt: Date;

  apkName?: string;
  summary?: string;
  libraries?: AnalysedLibrary[]
}

export type AnalysedLibrary = {
  name: string;
  analysis: string;
}

export type AuthorizationState = {
  authorized: boolean;
  justSignedOut?: boolean;
  lastPath?: string[];
  errorMessage?: string;
};

export type UploadState = {
  status: UploadStatus;
  name?: string;
  size?: number;
  type?: string;
  progress?: number;

}

export enum UploadStatus {
  Idle,
  InProgress,
  Done,
}